import clsx from "clsx";
import { getIn, useFormikContext } from "formik";
import { useTranslation } from "next-i18next";
import { Fragment } from "react";

interface Props {
  name: string;
  className?: string;
}

const ErrorField = ({ name, className }: Props) => {
  const { errors } = useFormikContext();
  const { t } = useTranslation();

  const error = getIn(errors, name);

  if (!error) {
    return <Fragment />;
  }

  return (
    <div className={clsx("text-red-500 text-sm mt-1", className)}>
      {t(error)}
    </div>
  );
};

export default ErrorField;
