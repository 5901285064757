import Script from "next/script";
import minify from "@sid/core/util/minify";

type Props = {
  id: string;
};

const GAnalytics = ({ id }: Props) => {
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${id}`}
        strategy="afterInteractive"
        id={id}
      />
      <Script id={`ga_${id}`} strategy="afterInteractive">
        {[
          `window.dataLayer = window.dataLayer || [];`,
          `function gtag(){dataLayer.push(arguments);}`,
          minify(`gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied',
            'functionality_storage': 'denied',
            'personalization_storage': 'denied',
            'security_storage': 'granted',
          });`),
          `gtag('js', new Date());`,
          `gtag('set', 'allow_google_signals', false);`,
          `gtag('config', '${id}');`,
        ].join("")}
      </Script>
    </>
  );
};

export default GAnalytics;
