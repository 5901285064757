import { useUserContext } from "@sid/core/context/UserContext";
import { assetPrefix } from "@sid/core/vars/assetUrl";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { Trans } from "next-i18next";
import { createContext, useContext } from "react";
import Button from "./Button";
import ModalComponent, { useModal } from "./Modal";

dayjs.extend(duration);

export const VerifyEmailEnforcementModal = () => {
  const { enforcementModal } = useVerifyEmailForcement();
  return (
    <ModalComponent {...enforcementModal} className="max-w-[350px]">
      <div className="text-center flex flex-col items-center">
        <h1 className="text-xl font-montserrat font-semibold">
          <Trans i18nKey="verify_your_email">Verifikasi Email mu!</Trans>
        </h1>
        <img
          src={assetPrefix + "images/verify-your-email.svg"}
          className="w-[250px] my-8"
          alt="verify your email"
        />
        <p>
          <Trans i18nKey="verify_your_email_desc">
            Halo, sepertinya email mu masih belum terverifikasi sampai saat ini.
            Yuk verifikasi email mu untuk terus menggunakan layan{" "}
            <strong>s.id</strong>
          </Trans>
        </p>
        <div className="mt-2 w-full">
          <Button
            type="button"
            color="primary"
            className="w-full"
            onClick={enforcementModal.close}
          >
            <Trans i18nKey="close" />
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};

const useVerifyEmailForcementContext = () => {
  const enforcementModal = useModal();
  const { user } = useUserContext();
  return {
    checkIsForcement: () => {
      const isForcement =
        user.status === 0 &&
        dayjs().isAfter(
          dayjs(user.created_at).add(dayjs.duration({ days: 1 }))
        );

      if (isForcement) {
        enforcementModal.setOpen(true);
      }

      return isForcement;
    },
    enforcementModal,
  };
};

const VerifyEmailEnforcementContext = createContext<
  ReturnType<typeof useVerifyEmailForcementContext>
>(null as any);

export const useVerifyEmailForcement = () =>
  useContext(VerifyEmailEnforcementContext);

export const VerifyEmailEnforcementProvider = ({ children }) => {
  const value = useVerifyEmailForcementContext();
  return (
    <VerifyEmailEnforcementContext.Provider value={value}>
      {children}
      <VerifyEmailEnforcementModal />
    </VerifyEmailEnforcementContext.Provider>
  );
};
