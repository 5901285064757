import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { useState } from "react";

export const useTogglePassword = () => {
  const [isOpen, setIsOpen] = useState(false);

  return {
    isOpen,
    close: () => setIsOpen(false),
    setToggle: () => {
      setIsOpen(!isOpen);
    },
  };
};

interface Props extends ReturnType<typeof useTogglePassword> {
  className?: string;
}

const TogglePassword = ({ isOpen, setToggle, className }: Props) => {
  return (
    <button
      type="button"
      className={clsx(
        "absolute top-0 bottom-0 right-0 px-3 text-gray-500",
        className
      )}
      onClick={(e) => {
        e.preventDefault();
        setToggle();
      }}
    >
      {isOpen ? (
        <EyeSlashIcon className="w-4 h-4" />
      ) : (
        <EyeIcon className="w-4 h-4" />
      )}
    </button>
  );
};

export default TogglePassword;
