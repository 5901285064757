import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { useFormikContext } from "formik";
import { Ref, forwardRef } from "react";

type Props = {
  onSuccess?();
  action?: string;
};

const InputCaptcha = forwardRef(function InputCaptcha(
  props: Props,
  ref: Ref<TurnstileInstance | undefined>
) {
  const { setFieldValue } = useFormikContext();
  return (
    <Turnstile
      ref={ref}
      siteKey={process.env.NEXT_PUBLIC_TURNSTILE || "1x00000000000000000000AA"}
      options={{
        theme: "light",
        action: props.action,
      }}
      onSuccess={(token) => {
        setFieldValue("captcha", token, false);

        if (typeof props.onSuccess !== "undefined") {
          props.onSuccess();
        }
      }}
    />
  );
});

export default InputCaptcha;
