import { IMicrosite } from "@sid/core/interface/microsite";
import Script from "next/script";
import React, { Fragment, createContext, useContext, useMemo } from "react";
import useSWR from "swr";
import { AdPlusScript } from "../components/common/AdsContainer";
import { useAppContext } from "./AppContext";
import { useUserContext } from "./UserContext";

interface AdsContextProviderInterface {
  children: React.ReactNode;
  ads?: AdsContextInterface;
  microsite?: IMicrosite;
  hideAds?: boolean;
}

interface AdVendorData {
  enabled: boolean;
  vendor: string;
  key: string;
  microsite: boolean;
  home: boolean;
  blog: boolean;
}

interface AdsContextInterface {
  enabled: boolean;
  key?: string;
  placement?: any;
  forceVendor?: string;
  force?: boolean;
  clients?: {
    gads: AdVendorData;
    adplus: AdVendorData;
    mgid_blog: AdVendorData & {
      sidebar: string;
      in_content: string;
      article_top: string;
      alt_sidebar: string;
      alt_in_content: string;
      alt_article_top: string;
    };
    mgid_home: AdVendorData;
  };
}

const AdsContext = createContext<AdsContextInterface>({} as any);

const AdsContextProvider = ({
  children,
  ads,
  microsite,
  hideAds,
}: AdsContextProviderInterface) => {
  const { subscription } = useUserContext();
  const { kind } = useAppContext();

  const adCap = useSWR(
    () =>
      kind === "MICROSITE" && microsite
        ? "/adcap?short=" + microsite.slug
        : null,
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    }
  );

  const enabled = useMemo(() => {
    let enabled = ads?.enabled || false;

    if (subscription?.stillActive) {
      enabled = false;
    }

    if (kind === "MICROSITE" && adCap.data?.data?.show_ad === false) {
      enabled = false;
    }

    if (hideAds === true) {
      enabled = false;
    }

    if (ads?.force) {
      enabled = true;
    }

    return enabled;
  }, [ads, subscription, kind, adCap, hideAds]);

  return (
    <AdsContext.Provider value={{ ...ads, enabled }}>
      {enabled && typeof ads !== "undefined" ? (
        <>
          <RenderGadsScript ads={ads} kind={kind} />
          <AdPlusScript kind={kind} />
        </>
      ) : (
        <Fragment />
      )}
      {children}
    </AdsContext.Provider>
  );
};

const RenderGadsScript = ({
  ads,
  kind,
}: {
  ads: AdsContextInterface;
  kind: string;
}) => {
  const isRender = useMemo(() => {
    if (!ads.forceVendor) ads.forceVendor = "";
    let isRender = false;

    let siteEnabled = false;

    if (kind === "MICROSITE" && ads.clients?.gads.microsite) {
      siteEnabled = true;
    }

    if (kind === "BLOG" && ads.clients?.gads.blog) {
      siteEnabled = true;
    }

    if (kind === "HOME" && ads.clients?.gads.home) {
      siteEnabled = true;
    }

    if (ads.forceVendor === "" && ads.clients?.gads.enabled && siteEnabled) {
      isRender = true;
    }

    if (ads.forceVendor === "gads") isRender = true;
    return isRender;
  }, [ads]);

  if (isRender) {
    return (
      <Script
        src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${ads?.clients?.gads?.key}`}
        crossOrigin="anonymous"
        async
        strategy="beforeInteractive"
      />
    );
  }
  return <Fragment />;
};

export const useAdsContext = () => useContext(AdsContext);

export default AdsContextProvider;
