import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import useSWR from "swr";
import UserAuthModal from "../components/UserAuthModal";
import { useModal } from "../components/common/Modal";
import { IUser, IUserSubscription } from "../interface/user";
import api from "@sid/core/util/api";

type AuthStatus = "loading" | "authenticated" | "unauthorized";

type UserSubscriptionConfig = {
  name: string;
  max_microsite: number;
  no_ads: boolean;
  query_range: number;
  hide_powered: boolean;
  max_edit_long_url: number;
  max_retention: number;
  max_microsite_component: number;
  edit_microsite_url: boolean;
  use_qrcode: boolean;
  max_qrcode: number;
  edit_url: boolean;
  max_domain?: number;
  max_domain_target?: number;
};

const userContext = () => {
  const authModal = useModal();
  const [status, setStatus] = useState<AuthStatus>("loading");
  const [isDark, setIsDark] = useState(false);
  const [isCompat, setIsCompat] = useState(false);
  const { pathname } = useRouter();
  const { t } = useTranslation();

  const {
    isLoading,
    data: user,
    error,
    mutate: mutateUser,
  } = useSWR(() => "/user/me", {
    dedupingInterval: 20000,
    revalidateOnFocus: true,
    revalidateOnMount: true,
    refreshInterval: 20000,
    errorRetryInterval: 5000,
  });

  const quota = useSWR(status === "authenticated" ? "/user/me/quota" : null, {
    dedupingInterval: 20000,
    revalidateOnFocus: false,
    revalidateOnMount: true,
    refreshInterval: 20000,
  });

  const subscription = useMemo(() => {
    const { data } = user || {};
    const expiredAt = data?.user_subscription?.expired_at;
    const status = data?.user_subscription?.status;
    const stillActive = data?.user_subscription?.still_active;

    return {
      ...(data?.user_subscription as IUserSubscription),
      expiredAt,
      stillActive,
      status,
      level: data?.user_subscription?.subscription?.config?.level || 0,
      config: (data?.user_subscription?.subscription?.config ||
        {}) as UserSubscriptionConfig,
      slug: data?.user_subscription?.subscription?.slug,
    };
  }, [user]);

  useEffect(() => {
    if (error) {
      if (error?.response?.status === 401) {
        if (error.response.data?.message) {
          switch (error.response.data.message) {
            case "cookie token is empty":
              setStatus("unauthorized");
              break;
            case "Token is expired":
              break;
            case "signature is invalid":
              setStatus("unauthorized");
              break;
            case "access_permitted":
              setStatus("unauthorized");
              break;
            default:
              toast.error(t("failed_fetch"));
              break;
          }
        }
      } else {
        toast.error(t("failed_fetch"));
      }
    } else if (user?.data) {
      setStatus("authenticated");
    } else if (!isLoading) {
      setStatus("unauthorized");
    }
  }, [isLoading, user, error]);

  useEffect(() => {
    if (window.localStorage?.theme === "dark") {
      setIsDark(true);
    }

    if (window.localStorage?.menuCompat === "true") {
      setIsCompat(true);
    }
  }, []);

  useEffect(() => {
    if (isDark && pathname.startsWith("/dashboard")) {
      window.document.documentElement.classList.add("dark");
      // localStorage.setItem("theme", "dark");
    } else {
      window.document.documentElement.classList.remove("dark");
      // localStorage.setItem("theme", "light");
    }
  }, [pathname, isDark]);

  const _user = useMemo(() => {
    const _user = { ...(user?.data || {}) } as IUser & {
      user_quota?: any;
      user_preferences: any;
    };
    _user.user_preferences = _user.user_preferences || {};
    _user.user_quota = _user.user_quota || {};
    if (quota.data?.data) {
      _user.user_quota = quota.data?.data;
    }

    if (_user?.user_preferences?.darkmode === true) {
      setIsDark(true);
      localStorage.setItem("theme", "dark");
    } else if (_user?.user_preferences?.darkmode === false) {
      setIsDark(false);
      localStorage.setItem("theme", "light");
    }

    return _user;
  }, [user, quota.data]);

  return {
    user: _user,
    status,
    revalidate: async () => {
      const user = await mutateUser();
      return user;
    },
    refreshQuota: async () => {
      return await quota.mutate();
    },
    isCompat,
    setIsCompat: (value) => {
      localStorage.setItem("menuCompat", value);
      setIsCompat(value);
    },
    isDark,
    subscription,
    setIsDark: (state) => {
      api().post("/user/preferences", {
        ..._user.user_preferences,
        tz: _user.user_preferences.tz || "GMT+07:00",
        darkmode: state,
      });
      setIsDark(state);
      localStorage.setItem("theme", state ? "dark" : "light");
    },
    authModal,
  };
};

const UserContext = createContext({} as ReturnType<typeof userContext>);

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserContextProvider = ({ children }) => {
  const value = userContext();
  return (
    <UserContext.Provider value={value}>
      {children}
      <UserAuthModal />
    </UserContext.Provider>
  );
};
