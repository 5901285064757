import clsx from "clsx";
import { useFormikContext } from "formik";
import React, { ComponentPropsWithRef, ReactNode } from "react";
import Spinner from "../icon/Spinner";
import styled from "./Button.module.scss";

type Props = {
  color?:
    | "primary"
    | "secondary"
    | "white"
    | "blue"
    | "dark"
    | "green"
    | "amber"
    | "";
  size?: "base" | "sm";
  outline?: boolean;
  active?: boolean;
  as?: ReactNode | string;
  href?: string;
  target?: string;
  roundFull?: boolean;
  rel?: string;
} & ComponentPropsWithRef<"button">;

//@ts-ignore
const Button = React.forwardRef<HTMLButtonElement, Props>(function Button(
  {
    className,
    color = "primary",
    size = "base",
    active: onActive = false,
    outline: onOutline,
    disabled,
    roundFull = false,
    as: Component,
    ...props
  }: Props,
  ref
) {
  const _className = clsx(
    className,
    styled.Btn,
    // colorStyle,
    color === "primary" && styled.Primary,
    color === "secondary" && styled.Secondary,
    color === "blue" && styled.Blue,
    color === "white" && styled.White,
    color === "dark" && styled.Dark,
    color === "green" && styled.Green,
    color === "amber" && styled.Amber,
    size === "base" && styled.SizeBase,
    size === "sm" && styled.SizeSm,
    onOutline ? styled.Outline : styled.Normal,
    onActive && styled.Active,
    roundFull ? styled.RoundedFull : styled.Round,
    disabled ? styled.Disabled : styled.NoDisabled
  );

  if (typeof Component !== "undefined") {
    if (typeof Component === "string") {
      if (Component === "href") {
        return (
          //@ts-ignore
          <a
            ref={ref as any}
            {...props}
            className={clsx(_className, "inline-block")}
          />
        );
      }
    }

    //@ts-ignore
    return <Component ref={ref as any} {...props} className={_className} />;
  }

  return (
    //@ts-ignore
    <button
      ref={ref as any}
      disabled={disabled}
      {...props}
      className={_className}
    />
  );
});

export const FormikSubmit = ({ children, ...props }: Props) => {
  const { isSubmitting, handleSubmit } = useFormikContext();
  return (
    <Button
      {...props}
      type="submit"
      disabled={isSubmitting || props.disabled}
      //@ts-ignore
      onClick={handleSubmit}
    >
      <>
        {isSubmitting && (
          <Spinner className="w-5 inline-block -mt-1 mr-2 animate-spin" />
        )}
        {children}
      </>
    </Button>
  );
};

export default Button;
