import { Fragment, useEffect } from "react";

import * as VCookieConsent from "vanilla-cookieconsent";

const CookieConsent = ({
  autoShow = true,
  autoEnabled = false,
  domain = "s.id",
}) => {
  const listenForConsent = () => {
    const updateGtagConsent = (force = false) => {
      let advertisement = VCookieConsent.acceptedCategory("advertisement");
      let analytics = VCookieConsent.acceptedCategory("analytics");
      let functional = VCookieConsent.acceptedCategory("function");

      if (force) {
        advertisement = true;
        analytics = true;
        functional = true;
      }

      //@ts-ignore
      window.gtag("consent", "update", {
        ad_storage: advertisement ? "granted" : "denied",
        ad_user_data: advertisement ? "granted" : "denied",
        ad_personalization: advertisement ? "granted" : "denied",
        analytics_storage: analytics ? "granted" : "denied",
        functionality_storage: functional ? "granted" : "denied",
        personalization_storage: functional ? "granted" : "denied",
        security_storage: "granted", //necessary
      });
    };

    window.addEventListener("cc:onConsent", () => {
      updateGtagConsent();
    });

    window.addEventListener("cc:onChange", () => {
      updateGtagConsent();
    });

    if (autoEnabled && VCookieConsent.validCookie("cc_cookie") === false) {
      setTimeout(() => {
        updateGtagConsent(true);
      }, 100);
    }
  };

  useEffect(() => {
    listenForConsent();
    VCookieConsent.run({
      cookie: {
        domain,
      },
      autoShow,
      guiOptions: {
        consentModal: {
          layout: "bar",
          position: "bottom",
          equalWeightButtons: true,
          flipButtons: true,
        },
      },
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        functional: {
          enabled: autoEnabled,
        },
        analytics: {
          enabled: autoEnabled,
        },
        // performance: {},
        advertisement: {
          enabled: autoEnabled,
        },
      },
      language: {
        default: "en",
        translations: {
          en: {
            consentModal: {
              title: "🍪 We use cookies",
              description:
                "Hello, this website uses essential cookies to ensure its proper functioning and tracking cookies to understand how you interact with it. The latter is only set after permission.",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: undefined,
              showPreferencesBtn: "Manage preferences",
              footer: `<a href="https://home.s.id/page/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>`,
            },
            preferencesModal: {
              title: "Manage cookie preferences",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Accept current selection",
              closeIconLabel: "Close modal",
              serviceCounterLabel: "Service|Services",
              sections: [
                {
                  title: "Your Privacy Choices",
                  description: `We use cookies to ensure basic website functionality and to improve your online experience. You can choose to opt in or out of each category whenever you want.`,
                },
                {
                  title: "Necessary",
                  description:
                    "Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.",
                  linkedCategory: "necessary",
                },
                {
                  title: "Functional",
                  description:
                    "Functional cookies help perform certain functionalities like sharing the content of the website on social media platforms, collecting feedback, and other third-party features.",
                  linkedCategory: "functional",
                },
                {
                  title: "Analytics",
                  description:
                    "Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.",
                  linkedCategory: "analytics",
                },
                {
                  title: "Advertisement",
                  description:
                    "Advertisement cookies are used to provide visitors with customized advertisements based on the pages you visited previously and to analyze the effectiveness of the ad campaigns.",
                  linkedCategory: "advertisement",
                },
                {
                  title: "More information",
                  description:
                    'For any queries in relation to our policy on cookies and your choices, please contact us at <a href="mailto:helpdesk@s.id">helpdesk@s.id</a>.',
                },
              ],
            },
          },
        },
      },
    });
  }, []);

  return <Fragment />;
};

export default CookieConsent;
