import { assetPrefix } from "@sid/core/vars/assetUrl";
import Head from "next/head";

const ErrorPage = () => {
  return (
    <div className="bg-slate-900 text-white h-screen flex flex-col justify-center items-center">
      <Head>
        <title>s.id - Something wen&apos;t wrong</title>
      </Head>
      <img
        src={assetPrefix + "images/maintenance.svg"}
        alt="Maintenance"
        className="w-[200px]"
      />
      <h1 className="text-4xl font-montserrat font-semibold mt-5">😟 Oops!</h1>
      <p className="text-lg mt-1 font-worksans">Something wen&apos;t wrong!</p>
    </div>
  );
};

export default ErrorPage;
