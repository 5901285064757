import axios, { AxiosError } from "axios";
import md5 from "crypto-js/md5";

interface Prop {
  locale?: string | null;
}

const api = (opts: Prop = { locale: null }) => {
  let lang = "";
  if (typeof window !== "undefined")
    lang = opts.locale || window?.localStorage?._lang || "en";

  let baseURL = "/api";

  if (
    (process.env.NODE_ENV === "production" ||
      process.env.NEXT_PUBLIC_DEV_FORCE === "true") &&
    process.env.API_PROXY !== "true"
  ) {
    baseURL = process.env.NEXT_PUBLIC_API_BASE || "/api";
  }

  const unix = Math.round(new Date().getTime() / 1000);
  const randString = (Math.random() + 1).toString(36).substring(6);
  const secret = process.env.NEXT_PUBLIC_DYNAMIC_SECRET;
  const key = process.env.NEXT_PUBLIC_DYNAMIC_KEY;
  const hashed = md5(`${unix},${randString},${secret},${key}`);

  const handler = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
      "X-RPC-Lang": lang,
      DS: unix + "," + randString + "," + hashed + "," + key,
    },
  });

  handler.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      return new Promise((resolve, reject) => {
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 401) {
            if (err.response?.data?.message === "Token is expired") {
              api()
                .post("/refresh-token")
                .then(() => {
                  api()
                    .request({ ...err.config, headers: {} })
                    .then((res) => resolve(res))
                    .catch((err) => reject(err));
                })
                .catch(() => {
                  reject(err);
                });
            } else {
              return reject(err);
            }
          } else {
            return reject(err);
          }
        }
      });
    }
  );

  return handler;
};

export const validateErrorsToObject = (value) => {
  const errors = {};

  value.forEach((item) => {
    errors[item.field] = item.reason;
  });

  return errors;
};

type handleAxiosErrorProp = {
  setErrors?: any
  toast: any
  t?: any
  onError?: any
}

export const handleAxioError = (
  e: AxiosError,
  { setErrors, toast, t, onError }: handleAxiosErrorProp,
) => {
  if (typeof t === 'undefined') {
    t = (m) => m
  }
  const resGlobal = (e) => {
    toast.error(
      e.response.data?.message
        ? t(e.response.data?.message)
        : t("connection_error")
    );
  };

  if (e.response) {
    if (e.response.data?.errors && typeof setErrors === "function") {
      setErrors(validateErrorsToObject(e.response.data.errors));
    } else {
      if (e.response.data?.message) {
        if (typeof onError === "function") {
          if (!onError(e.response.data?.message, e.response.data?.code)) {
            resGlobal(e);
          }
        } else {
          resGlobal(e);
        }
      } else {
        resGlobal(e);
      }
    }
  }
};

export const fetcher = async ({ locale, url }) => {
  return api({ locale })
    .get(url)
    .then(({ data }) => data);
};

export default api;
