import { useEffect, useRef, useState } from "react";

const UletLogin = () => {
  const [show, setShow] = useState(false);
  const mouseListener = useRef<any>();

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);

    if (typeof window !== "undefined") {
      // Create the eyeball elements
      const eyeballs = document.querySelectorAll(".eyeball");

      // Set the dimensions of the eyeballs and pupils
      const pupilSize = 16;

      mouseListener.current = (event) => {
        // Get the mouse position
        const mouseX = event.clientX;
        const mouseY = event.clientY;

        // Loop through each eyeball
        eyeballs.forEach((eyeball) => {
          // Get the center coordinates of the eyeball
          const eyeballRect = eyeball.getBoundingClientRect();
          const eyeballCenterX = eyeballRect.width / 2;
          const eyeballCenterY = eyeballRect.height / 2;

          // Calculate the distance between the mouse and the eyeball center
          const distance = Math.sqrt(
            (mouseX - eyeballRect.x + eyeballCenterX) ** 2 +
              (mouseY - eyeballRect.y + eyeballCenterY) ** 2
          );

          // Calculate the maximum movement distance of the pupil
          const maxDistance = (eyeballRect.width - pupilSize) / 2;

          // Calculate the normalized distance within the eyeball radius
          const normalizedDistance = Math.min(distance, maxDistance);

          // Calculate the angle between the mouse and the eyeball center
          const angle = Math.atan2(
            mouseY - eyeballRect.y + eyeballCenterY,
            mouseX - eyeballRect.x + eyeballCenterX
          );

          // Calculate the new position of the pupil
          const pupilX = eyeballCenterX + Math.cos(angle) * normalizedDistance;
          const pupilY = eyeballCenterY + Math.sin(angle) * normalizedDistance;

          // Set the new position of the pupil
          const pupil = eyeball.querySelector(".pupil");
          //@ts-ignore
          pupil.style.transform = `translate(${pupilX - pupilSize / 2}px, ${
            pupilY - pupilSize / 2
          }px)`;
        });
      };

      // Add event listener to track mouse movements
      document.addEventListener("mousemove", mouseListener.current);
    }

    return () => {
      document.removeEventListener("mousemove", mouseListener.current);
    };
  }, []);

  return (
    <div className="">
      <div
        style={{
          left: "23px",
          top: "10.58px",
          width: 25,
          height: 25,
        }}
        className="bg-[#f3e6e6] absolute rounded-full eyeball"
      >
        <div
          className="pupil bg-black w-4 h-4 rounded-full relative"
          style={{
            transform: "translate(4.40867px, 8.99907px)",
          }}
        >
          <div className="bg-white w-1 h-1 rounded-full top-[0.18rem] right-[0.25rem] absolute" />
        </div>
      </div>

      <div
        style={{
          left: "47px",
          top: "22px",
          width: 25,
          height: 25,
        }}
        className="bg-[#f3e6e6] absolute rounded-full eyeball"
      >
        <div
          className="pupil bg-black w-4 h-4 rounded-full relative"
          style={{
            transform: "translate(4.40867px, 8.99907px)",
          }}
        >
          <div className="bg-white w-1 h-1 rounded-full top-[0.18rem] right-[0.25rem] absolute" />
        </div>
      </div>
    </div>
  );
};

export default UletLogin;
