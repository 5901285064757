import api from "@sid/core/util/api";
import { assetPrefix } from "@sid/core/vars/assetUrl";
import Head from "next/head";
import React from "react";

type Props = {
  kind?: string;
  hasError?: boolean;
  children?: React.ReactNode;
};

class ErrorBoundary extends React.Component<Props, Props> {
  constructor(props: Props) {
    super(props);
    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, kind: props.kind };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error) {
    // You can use your own error logging service here
    // console.log({ error, errorInfo });
    api().post("/report-err", {
      e: error.message,
      s: error.stack,
      c: this.state.kind,
      p: window.location?.pathname,
      t: Math.round(new Date().getTime() / 1000),
    });
  }
  render() {
    // Check if the error is thrown
    // @ts-ignore
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="h-screen text-white flex flex-col items-center justify-center">
          <Head>
            <title>s.id - Something wen&apos;t wrong</title>
          </Head>
          <style>{`body { background: #19252D; }`}</style>
          <div className="max-w-[400x] mx-6">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:mr-8">
                <img
                  src={`${assetPrefix}images/fixed.svg`}
                  alt="Error 500"
                  className="w-[150px] md:w-[200px]"
                />
              </div>
              <div className="font-montserrat w-72 text-center md:text-left">
                <h1 className="text-5xl md:text-8xl font-extrabold mt-6 md:mt-0 text-amber-100">
                  Oops!
                </h1>
                <h2 className="text-sm md:text-xl mt-3 md:mt-4">
                  Something wen&apos;t wrong!
                </h2>
              </div>
            </div>
            <div className="flex justify-center pt-3 mt-3">
              <img
                src={assetPrefix + "images/sid-neu-logo.svg"}
                className="h-6 md:h-8"
                alt="Error 404"
              />
            </div>
          </div>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
