import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import React, { Fragment, useState } from "react";
import Spinner from "../icon/Spinner";
import Button from "./Button";

interface useModalProp {
  isOpen?: boolean;
}

export const useModal = (props: useModalProp = { isOpen: false }) => {
  const [item, setItem] = useState();
  const [isOpen, setOpen] = useState(props.isOpen);
  const [isLoading, setIsLoading] = useState(false);
  const toggle = () => setOpen(!isOpen);
  const open = (item?: any) => {
    setOpen(true);
    if (item) {
      setItem(item);
    }
  };
  const close = () => setOpen(false);
  const setLoading = () => setIsLoading(true);
  const finishLoading = () => setIsLoading(false);

  return {
    isOpen,
    open,
    setOpen,
    toggle,
    close,
    isLoading,
    setLoading,
    finishLoading,
    item,
  };
};

interface Props extends ReturnType<typeof useModal> {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  blur?: boolean;
}

const ModalComponent = ({
  isOpen,
  setOpen,
  children,
  disabled,
  isLoading,
  className,
  blur = false,
}: Props) => {
  const closeModal = () => {
    if (disabled || isLoading) return;
    setOpen(false);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={clsx("fixed inset-0 z-50 overflow-y-auto")}
        style={{ zIndex: 9999999 }}
        onClose={closeModal}
      >
        {blur && (
          <div className="bg-black h-screen w-screen fixed bg-opacity-10 backdrop-blur-sm" />
        )}
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-90"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-90"
          >
            <div
              className={clsx(
                "inline-block w-full max-w-md p-4 text-left align-middle transition-all transform shadow-xl rounded-lg",
                "bg-white",
                "dark:bg-gray-950 dark:border dark:border-gray-800 dark:text-white",
                className
              )}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const ConfirmModal = ({ title, content, onOk, modalProp }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      if (await onOk()) {
        modalProp.close();
      }
    } catch (e) {
      //...
    }
    setIsLoading(false);
  };

  return (
    <ModalComponent {...modalProp}>
      <div className="flex items-center">
        <h1 className="flex-1 text-xl font-semibold font-montserrat">
          {title}
        </h1>
        <button className="p-1" onClick={modalProp.close}>
          <XMarkIcon className="w-6" />
        </button>
      </div>
      <div className="py-3">{content}</div>
      <div className="flex gap-2 mt-2 justify-end">
        <Button
          color="secondary"
          className="min-w-[80px]"
          disabled={isLoading}
          onClick={modalProp.close}
        >
          {t("cancel")}
        </Button>
        <Button
          color="primary"
          className="min-w-[80px]"
          onClick={onConfirm}
          disabled={isLoading}
        >
          {isLoading && (
            <Spinner className="w-4 inline-block animate-spin mr-1 -mt-1" />
          )}
          {t("yes")}
        </Button>
      </div>
    </ModalComponent>
  );
};

export const ModalHeader = ({ children, close, disabled = false }) => {
  return (
    <div className="flex items-center mb-3">
      <h1 className="font-montserrat text-xl font-semibold flex-1">
        {children}
      </h1>
      <button onClick={close} disabled={disabled}>
        <XMarkIcon className="w-6" />
      </button>
    </div>
  );
};

export default ModalComponent;
