import { animated, easings, useSpring } from "@react-spring/web";
import React, { useState } from "react";
import { useMeasure } from "react-use";

type UseSlideDown = {
  open?: boolean;
};

export const useSlideDown = (props?: UseSlideDown) => {
  const [isOpen, setOpen] = useState(props?.open || false);
  return {
    isOpen,
    setOpen,
    toggle: () => setOpen((state) => !state),
    open: () => setOpen(true),
    close: () => setOpen(false),
  };
};

type SlideDownProps = {
  duration?: number;
} & ReturnType<typeof useSlideDown> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const SlideDown = ({
  children,
  isOpen,
  duration = 750,
}: SlideDownProps) => {
  const [ref, { height }] = useMeasure();
  const [isAnimated, setIsAnimated] = useState(false);

  const propStyle = useSpring({
    height: isOpen ? height : 0,
    config: {
      duration,
      easing: easings.easeOutExpo,
    },
    onStart: () => {
      setIsAnimated(true);
    },
    onRest: () => {
      setIsAnimated(false);
    },
  });

  const overflow = isAnimated ? "hidden" : !isOpen ? "hidden" : "unset";

  return (
    <animated.div style={{ ...propStyle, overflow }}>
      <animated.div ref={ref as any}>{children}</animated.div>
    </animated.div>
  );
};
