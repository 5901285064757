import { Adsense } from "@ctrl/react-adsense";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useAdsContext } from "@sid/core/context/AdsContext";
import { useAppContext } from "@sid/core/context/AppContext";
import minify from "@sid/core/util/minify";
import clsx from "clsx";
import { Trans } from "next-i18next";
import { useRouter } from "next/router";
import Script from "next/script";
import { Fragment, useCallback, useEffect, useMemo } from "react";
import { useWindowSize } from "react-use";

type Props = {
  name: string;
  className?: string;
};

type AdPlusID =
  | "protag-native-after_content"
  | "protag-before_content"
  | "protag-after_content"
  | "protag-in_content"
  | "protag-sidebar"
  | "protag-header"
  | "protag-sticky-bottom";

type AdPlusProp = {
  id: AdPlusID;
};

const mobileDimen = 767;

const AdsContainer = ({ name, className }: Props) => {
  const ads = useAdsContext();
  const { locale } = useRouter();
  const { width: windowWidth } = useWindowSize();
  const ad = useMemo(() => {
    return ads.placement ? ads.placement[name] : {};
  }, [name]);

  const getAdImage = useCallback(
    (item) => {
      if (windowWidth <= mobileDimen) {
        return item?.imgMobile || item?.img;
      }
      return item?.img;
    },
    [windowWidth]
  );

  const imageStyle = useMemo(() => {
    if (!ad) return {};
    if (windowWidth <= mobileDimen) {
      return ad.imageStyleMobile || ad.imageStyle;
    }
    return ad.imageStyle || {};
  }, [windowWidth]);

  if (!ad || !ads.enabled) return <Fragment />;

  if (ad.dummies === true) {
    return (
      <div
        style={ad.style ? ad.style : {}}
        className={clsx(
          className,
          "bg-black bg-opacity-5 dark:bg-gray-900 dark:bg-opacity-50",
          ad?.className
        )}
      >
        <a
          href={
            locale === "id"
              ? "https://s.id/idbannerads"
              : "https://s.id/enbannerads"
          }
          className="flex justify-center items-center h-full"
          target="_blank"
          rel="noreferrer"
        >
          <div className="min-h-[150px] flex items-center justify-center text-gray-500 lg:text-lg">
            <Trans i18nKey="ads_with_us">Advertise with us</Trans>
          </div>
        </a>
      </div>
    );
  }

  if (
    (ad?.adplus === true && ad?.adplus_id?.length > 0) ||
    (ads.forceVendor === "adplus" && ad?.adplus_id?.length > 0)
  ) {
    return <AdPlus id={ad?.adplus_id} />;
  }

  if (ad?.mgid?.enabled) {
    return (
      <div className="w-full bg-white p-2 rounded-lg">
        <div data-type="_mgwidget" data-widget-id={ad.mgid.id}></div>
        <Script
          id={"ad_" + ad.mgid.id}
        >{`(function(w,q){w[q]=w[q]||[];w[q].push(["_mgc.load"])})(window,"_mgq");`}</Script>
      </div>
    );
  }

  if (ad.image) {
    return (
      <div
        className={clsx(
          "bg-gray-200 dark:bg-gray-900 w-full relative rounded-md overflow-hidden",
          className
        )}
      >
        <a href={ad.image.href} target="_blank">
          <img
            style={imageStyle}
            src={getAdImage(ad.image)}
            className="w-full h-full object-contain"
            alt=""
          />
        </a>
        <div className="absolute bottom-1 right-1 bg-black text-white text-sm px-1 rounded">
          Ads
        </div>
      </div>
    );
  }

  if (ad.adsterra?.active) {
    return <AdsTerraItem item={ad.adsterra} />;
  }

  return (
    <Adsense
      slot={ad.slot}
      format={ad.format}
      layout={ad.layout}
      layoutKey={ad.layoutKey}
      pageLevelAds={ad.pageLevelAds}
      responsive={ad.responsive}
      style={ad.style}
      client={ads.clients?.gads.key || ""}
      className={clsx(
        className,
        // "bg-black bg-opacity-5 dark:bg-gray-900 dark:bg-opacity-50",
        ad?.className
      )}
      adTest={process.env.NODE_ENV !== "production" ? "on" : "off"}
    />
  );
};

export const StickyBottomAds = () => {
  const ads = useAdsContext();
  const { asPath } = useRouter();
  const { kind, showAds, setShowAds } = useAppContext();

  useEffect(() => {
    if (showAds && kind === "HOME") {
      if (asPath.startsWith("/dashboard/microsite/")) {
        setShowAds(false);
      } else if (asPath.startsWith("/auth")) {
        setShowAds(false);
      } else {
        setShowAds(true);
      }
    }
  }, [asPath]);

  const { isShow, adItem } = useMemo(() => {
    let adObj: any = {};
    if (kind === "BLOG") {
      adObj = ads.placement ? ads.placement["blog_sid_sticky"] : {};
    } else if (kind === "HOME") {
      adObj = ads.placement ? ads.placement["home_sid_sticky"] : {};
    } else if (kind === "MICROSITE") {
      adObj = ads.placement ? ads.placement["microsite_sid_sticky"] : {};
    }
    return { isShow: adObj?.enabled === true, adItem: adObj };
  }, [showAds]);

  if (!ads.enabled) return <Fragment />;
  if (!isShow) return <Fragment />;

  if (ads.forceVendor === "adplus" && kind === "MICROSITE") {
    return <AdsContainer name="microsite_sid_sticky" />;
  }

  return (
    <div
      className={clsx(
        "container sticky-ads mx-auto bg-gray-200 dark:bg-gray-800 font-worksans relative",
        {
          "lg:hidden": kind !== "MICROSITE",
          "max-w-[500px]": kind === "MICROSITE",
        }
      )}
    >
      <div className={clsx("relative z-50", !showAds && "hidden")}>
        {!adItem.adplus && (
          <button
            className="absolute bg-red-500 -top-8 left-3 rounded-t-md p-1"
            onClick={() => {
              setShowAds(false);
            }}
            aria-label="close ad"
          >
            <XMarkIcon className="w-6 text-white" />
          </button>
        )}
        {kind === "BLOG" && <AdsContainer name="blog_sid_sticky" />}
        {kind === "HOME" && <AdsContainer name="home_sid_sticky" />}
        {kind === "MICROSITE" && <AdsContainer name="microsite_sid_sticky" />}
      </div>
    </div>
  );
};

export const AdPlus = ({ id }: AdPlusProp) => {
  return (
    <Fragment>
      {id !== "protag-sticky-bottom" && <div id={id}></div>}
      <div
        dangerouslySetInnerHTML={{
          __html: `
        <script type="text/javascript">
        window.googletag = window.googletag || { cmd: [] };
        window.protag = window.protag || { cmd: [] };
        window.protag.cmd.push(function () {
          window.protag.display("${id}");
        });
        </script>
        `,
        }}
      />
    </Fragment>
  );
};

export const AdsTerraItem = ({ item }) => {
  const display = useMemo(() => {
    return item?.item || null;
  }, [item]);

  if (!display) {
    return <Fragment />;
  }

  if (display.html) {
    return <div dangerouslySetInnerHTML={{ __html: display?.html }} />;
  }

  return (
    <div
      id={`adsterra-${display.key}`}
      dangerouslySetInnerHTML={{
        __html: minify(`<script type="text/javascript">
    atOptions = ${JSON.stringify({
      key: display.key,
      format: display.format,
      height: display.height,
      width: display.width,
      params: display.params,
    })}
  </script>
  <script type="text/javascript" src="//www.topcreativeformat.com/${
    display.key
  }/invoke.js" data-cfasync="false" async></script>`),
      }}
    />
  );
};

type AdPlusScriptProps = {
  kind: "HOME" | "BLOG" | "MICROSITE" | "ADMIN";
};

export const AdPlusScript = ({ kind }: AdPlusScriptProps) => {
  const ads = useAdsContext();

  const isRender = useMemo(() => {
    if (!ads.forceVendor) ads.forceVendor = "";
    let isRender = false;
    let siteEnabled = false;

    if (kind === "MICROSITE" && ads.clients?.adplus.microsite) {
      siteEnabled = true;
    }

    if (kind === "BLOG" && ads.clients?.adplus.blog) {
      siteEnabled = true;
    }

    if (ads.forceVendor === "" && ads.clients?.adplus && siteEnabled) {
      isRender = true;
    }
    if (ads.forceVendor === "adplus") isRender = true;
    return isRender;
  }, [ads, kind]);

  return <Fragment />;
};

export default AdsContainer;
