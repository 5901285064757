import React, { createContext, useContext, useState } from "react";

interface AppContextInterface {
  kind: "HOME" | "BLOG" | "MICROSITE" | "ADMIN";
  alternate?: string;
  disposableLink?: { enabled: boolean };
  protectedLink?: { enabled: boolean };
  verifyForm?: { enabled: boolean };
  ticketForm?: { enabled: boolean };
}

const useAppContextHook = ({
  kind,
  protectedLink,
  disposableLink,
  ticketForm,
  verifyForm,
  alternate,
}: AppContextInterface) => {
  const [showAds, setShowAds] = useState(true);

  return {
    kind,
    showAds,
    alternate,
    setShowAds,
    disposableLink: disposableLink?.enabled,
    protectedLink: protectedLink?.enabled,
    ticketForm,
    verifyForm,
  };
};

const AppContext = createContext<ReturnType<typeof useAppContextHook>>(
  {} as any
);

type AppContextProviderProps = {
  value: AppContextInterface;
  children: React.ReactNode;
};

const AppContextProvider = ({ children, value }: AppContextProviderProps) => {
  const _value = useAppContextHook({
    kind: value.kind,
    alternate: value.alternate,
    disposableLink: value?.disposableLink || { enabled: false },
    protectedLink: value?.protectedLink || { enabled: false },
    verifyForm: value?.verifyForm || { enabled: false },
    ticketForm: value?.ticketForm || { enabled: false },
  });
  return <AppContext.Provider value={_value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);

export default AppContextProvider;
