import { EnvelopeIcon } from "@heroicons/react/24/outline";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDownIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import { TurnstileInstance } from "@marsidev/react-turnstile";
import { useGoogleLogin } from "@react-oauth/google";
import api, { handleAxioError } from "@sid/core/util/api";
import { appleAppId, appleRedirectURL } from "@sid/core/vars/apple";
import axios from "axios";
import clsx from "clsx";
import { Form, Formik } from "formik";
import { Trans, useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import AppleSignin from "react-apple-signin-auth";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useUserContext } from "../context/UserContext";
import appScheme from "../vars/appScheme";
import { assetPrefix } from "../vars/assetUrl";
import UletLogin from "./UletLogin";
import style from "./UserAuthModal.module.scss";
import { SlideDown, useSlideDown } from "./anim/SlideDown";
import Button, { FormikSubmit } from "./common/Button";
import ErrorField from "./common/ErrorField";
import { FieldInput, InputLabel } from "./common/Input";
import InputCaptcha from "./common/InputCaptcha";
import ModalComponent, { ModalHeader } from "./common/Modal";
import TogglePassword, { useTogglePassword } from "./common/TogglePassword";
import Spinner from "./icon/Spinner";

const sendToWebkit = (data) => {
  const usp = new URLSearchParams();
  usp.set("token", data.token);
  usp.set("refresh_token", data.refresh_token);
  usp.set("exp", data.exp);
  usp.set("iat", data.iat);
  const goto = `${appScheme}.auth://?${usp.toString()}`;
  return goto;
};

const UserAuthModal = () => {
  const { query, pathname, push } = useRouter();
  const { authModal } = useUserContext();
  const prevIsOpen = useRef(false);

  const referral = useMemo(() => {
    if (query?.r && pathname === "/") {
      return query?.r;
    }

    if (query?.r && pathname.startsWith("/single")) {
      return query?.r;
    }

    return null;
  }, [query]);

  useEffect(() => {
    if (referral) {
      authModal.open({ noRedirect: true });
    }
  }, [referral]);

  useEffect(() => {
    if (
      !authModal.isOpen &&
      prevIsOpen.current &&
      referral &&
      pathname === "/"
    ) {
      push("/");
    }
    prevIsOpen.current = authModal.isOpen || false;
  }, [authModal.isOpen, referral]);

  return (
    <ModalComponent
      {...authModal}
      className="!max-w-sm mt-20 mb-20"
      blur
      // @ts-ignore
      disabled={authModal.item?.mobile}
    >
      <div
        className={clsx(
          //@ts-ignore
          authModal.item?.mobile ? "opacity-0 pointer-events-none" : ""
        )}
      >
        <ModalHeader {...authModal}>&nbsp;</ModalHeader>
      </div>
      {authModal.isOpen ? (
        <ModalContent referral={referral} />
      ) : (
        <div className="h-[300px] flex items-center justify-center" />
      )}
    </ModalComponent>
  );
};

const ModalContent = ({ referral }) => {
  const { t } = useTranslation();
  const { replace, query, pathname, locale } = useRouter();
  const user = useUserContext();
  const [panel, setPanel] = useState("MAIN");
  const [isLoading, setIsLoading] = useState(false);
  const [isFocusPassword, setIsFocusPassword] = useState(false);
  const [tokenId, setTokenId] = useState(null);
  const [fullName, setFullName] = useState("");
  const [signVendor, setSignVendor] = useState("");
  const [jwtTkn, setJwtTkn] = useState("");

  useEffect(() => {
    if (pathname.startsWith("/auth/forgot")) {
      setPanel("MAIL_REQUEST_FORGOT");
    }
  }, []);

  const responseGoogle = async (value) => {
    if (!value.access_token) {
      user.authModal.finishLoading();
      return;
    }

    user.authModal.setLoading();
    setIsLoading(true);
    try {
      const headers = {};
      //@ts-ignore
      if (user.authModal.item?.mobile) {
        headers["X-Use-Token"] = "true";
      }
      const { data } = await api().post(
        "/social/google",
        {
          token_id: value.access_token,
          newsletter: value?.newsletter,
          referral: value?.referral,
        },
        { headers }
      );
      //@ts-ignore
      if (data.data && user.authModal.item?.mobile) {
        setJwtTkn(sendToWebkit(data.data));
        setPanel("MOBILE_SUCCESS");
      }
      await user.revalidate();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response) {
          if (e.response?.status === 422) {
            if (e.response.data?.message === "not_registered") {
              setTokenId(value.access_token);
              setSignVendor("google");
              setPanel("SSO_REGISTER_FINAL");
            } else if (e.response?.data?.message === "email_already_exist") {
              setPanel("SSO_EMAIL_EXISTS");
            } else {
              toast.error(e.response.data.message);
              setIsLoading(false);
            }
          } else if (e.response?.status === 401) {
            toast.error(t("error_google_sign_in"));
          } else {
            toast.error(
              e.response.data?.message
                ? t(e.response.data?.message)
                : t("connection_error")
            );
          }
        }
        user.authModal.finishLoading();
      }
    }
    setIsLoading(false);
  };

  const responseApple = async (value) => {
    if (!value.access_token) {
      user.authModal.finishLoading();
      return;
    }

    user.authModal.setLoading();
    setIsLoading(true);
    try {
      const headers = {};
      //@ts-ignore
      if (user.authModal.item?.mobile) {
        headers["X-Use-Token"] = "true";
      }
      const { data } = await api().post(
        "/social/apple",
        {
          full_name: value?.full_name,
          token_id: value?.access_token,
          newsletter: value?.newsletter,
          referral: value?.referral,
        },
        { headers }
      );
      //@ts-ignore
      if (data.data && user.authModal.item?.mobile) {
        setJwtTkn(sendToWebkit(data.data));
        setPanel("MOBILE_SUCCESS");
      }
      await user.revalidate();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response) {
          if (e.response?.status === 422) {
            if (e.response.data?.message === "not_registered") {
              setTokenId(value.access_token);
              setFullName(value.full_name || "");
              setSignVendor("apple");
              setPanel("SSO_REGISTER_FINAL");
            } else if (e.response?.data?.message === "email_already_exist") {
              setPanel("SSO_EMAIL_EXISTS");
            } else {
              toast.error(e.response.data.message);
              setIsLoading(false);
            }
          } else if (e.response?.status === 401) {
            toast.error(t("error_apple_sign_in"));
          } else {
            toast.error(
              e.response.data?.message
                ? t(e.response.data?.message)
                : t("connection_error")
            );
          }
        }
        user.authModal.finishLoading();
      }
    }
    setIsLoading(false);
  };

  const signIn = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: () => {
      setIsLoading(false);
      user.authModal.finishLoading();
    },
    onNonOAuthError: () => {
      setIsLoading(false);
      user.authModal.finishLoading();
    },
    // flow: "auth-code",
    // ux_mode: "popup",
    // select_account: true,
  });

  const onSignUID = () => {
    const winHeight = 600;
    const winWidth = 800;
    const winTop = screen.height / 2 - winHeight / 2;
    const winLeft = screen.width / 2 - winWidth / 2;
    setIsLoading(true);
    user.authModal.setLoading();
    const popup = window.open(
      `https://u.id/oauth/authorize?client_id=${process.env.NEXT_PUBLIC_UID_CLIENT_ID}&redirect_uri=${process.env.NEXT_PUBLIC_UID_CALLBACK}&response_type=code`,
      "uid-login",
      "top=" +
        winTop +
        ",left=" +
        winLeft +
        ",toolbar=0,status=0,width=" +
        winWidth +
        ",height=" +
        winHeight
    );

    const intervalRef = setInterval(() => {
      if (popup && popup.closed) {
        clearInterval(intervalRef);
        setIsLoading(false);
        user.authModal.finishLoading();
      }
    }, 1000);
  };

  useEffect(() => {
    /**
     * Login via UID Callback
     */
    (window as any).uidCallback = async (code) => {
      await new Promise((r) => setTimeout(r, 1000));
      try {
        user.authModal.setLoading();
        setIsLoading(true);
        const headers = {};
        //@ts-ignore
        if (user.authModal.item?.mobile) {
          headers["X-Use-Token"] = "true";
        }
        const { data } = await api().post("/social/uid", { code }, { headers });
        //@ts-ignore
        if (data.data && user.authModal.item?.mobile) {
          setJwtTkn(sendToWebkit(data.data));
          setPanel("MOBILE_SUCCESS");
        }
        await user.revalidate();
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response) {
            if (e.response?.status === 422) {
              toast.error(e.response.data.message);
            } else if (e.response?.status === 401) {
              toast.error(t("error_uid_sign_in"));
            } else {
              toast.error(
                e.response.data?.message
                  ? t(e.response.data?.message)
                  : t("connection_error")
              );
            }
          }
        }
        user.authModal.finishLoading();
      }
      setIsLoading(false);
    };
  }, [t, user]);

  useEffect(() => {
    if (user.status === "authenticated") {
      setPanel("COMPLETED");
      //@ts-ignore
      if (query?.next || user.authModal.item?.next) {
        const next =
          //@ts-ignore
          (user.authModal.item?.next as string) || (query?.next as string);
        try {
          new URL(next);
          //@ts-ignore
          window.location = `/${locale}/dashboard`;
        } catch (e) {
          let _next = next;
          if (referral) {
            const qp = new URL(`https://home.s.id${next}`);
            qp.searchParams.set("r", referral);
            _next = qp.toString().split("https://home.s.id")[1];
          }
          //@ts-ignore
          window.location = `/${locale}${
            _next.startsWith("/") ? _next : `/${_next}`
          }`;
        }
      } else {
        //@ts-ignore
        if (user.authModal?.item?.noRedirect) {
          user.authModal.close();
          if (query.mobile === "true") {
            api()
              .get("/user/logout")
              .then(async () => {
                await user.revalidate();
                window.location.reload();
              });
          }
        } else {
          //@ts-ignore
          window.location = `/${locale}/dashboard`;
        }
      }
    }
  }, [replace, user.status, query, referral, user.authModal, locale]);

  return (
    <Fragment>
      {user.status !== "loading" && (
        <div
          className={clsx(
            "duration-500",
            "w-24 h-24 mx-auto rounded-full bg-blue-700 bg-center bg-cover relative overflow-hidden transition-all",
            isLoading ? "-mt-6" : "-mt-24"
          )}
          style={{
            backgroundImage: `url(${assetPrefix}images/saidi.svg)`,
          }}
        >
          <UletLogin />
          <div
            className={clsx(
              "duration-300 rounded-full scale-150 z-10",
              "transition-all inset-0 absolute bg-yellow-500",
              isFocusPassword ? "-translate-y-12" : "-translate-y-32"
            )}
          />

          <div
            className={clsx(
              "duration-300 rounded-full scale-150",
              "transition-all inset-0 absolute bg-yellow-700",
              isFocusPassword ? "translate-y-14" : "translate-y-32"
            )}
          />
        </div>
      )}
      {panel === "MAIN" && user.status !== "loading" ? (
        <img
          src={assetPrefix + "images/sid-neu-logo-dark.svg"}
          // src={assetPrefix + "images/sid-neu-logo-dark.svg"}
          alt="Logo"
          className="h-10 mx-auto mt-5 mb-2"
        />
      ) : (
        <Fragment />
      )}

      {user.status === "loading" && <div className="min-h-[300px]" />}

      {(user.status === "loading" || isLoading) && (
        <div className="absolute bg-white inset-0 flex items-center justify-center bg-opacity-70 rounded-md">
          <Spinner className="w-10 animate-spin" />
        </div>
      )}

      {user.status === "unauthorized" && (
        <Fragment>
          {panel === "MAIN" ? (
            <Fragment>
              <p className="text-center mb-5 text-gray-500">
                <Trans i18nKey="please_select_auth_method">
                  Please select authenticate method
                </Trans>
              </p>

              {/* @ts-ignore */}
              {user.authModal.item?.message && (
                <div className="bg-orange-50 text-orange-500 text-center p-2 rounded-md text-sm">
                  {/* @ts-ignore */}
                  {user.authModal.item?.message}
                </div>
              )}

              {referral && (
                <div className="bg-blue-50 text-blue-500 text-center p-3 rounded-md">
                  <Trans i18nKey="referral_using">You are using referral</Trans>{" "}
                  <strong>{referral}</strong>
                </div>
              )}

              <button
                className={style.AuthBtn}
                onClick={setPanel.bind(
                  null,
                  referral ? "MAIL_REGISTER" : "MAIL"
                )}
              >
                <div className={style.AuthIcon}>
                  <EnvelopeIcon className="text-gray-600 w-5" />
                </div>
                <p className={style.AuthBtnLabel}>
                  <Trans i18nKey="continue_with_email">
                    Continue with Email
                  </Trans>
                </p>
              </button>
              <button
                className={style.AuthBtn}
                onClick={() => {
                  signIn({ prompt: "select_account" });
                  setIsLoading(true);
                  user.authModal.setLoading();
                }}
              >
                <div className={style.AuthIcon}>
                  <img
                    src={assetPrefix + "images/google-icon.svg"}
                    alt="Google"
                    className="p-1"
                  />
                </div>
                <p className={style.AuthBtnLabel}>
                  <Trans i18nKey="continue_with_google">
                    Continue with Google
                  </Trans>
                </p>
              </button>
              <button className={style.AuthBtn} onClick={onSignUID}>
                <div className={style.AuthIcon}>
                  <img src={assetPrefix + "images/uid-logo.png"} alt="U.id" />
                </div>
                <p className={style.AuthBtnLabel}>
                  <Trans i18nKey="continue_with_uid">Continue with U.id</Trans>
                </p>
              </button>

              <AppleSignin
                authOptions={{
                  clientId: appleAppId,
                  scope: "name email",
                  redirectURI: appleRedirectURL,
                  state: "state",
                  nonce: "nonce",
                  usePopup: true,
                }}
                onError={() => {
                  setIsLoading(false);
                }}
                uiType="dark"
                onSuccess={(res) => {
                  setIsLoading(false);
                  responseApple({
                    access_token: res.authorization.id_token,
                    full_name: res.user
                      ? [res.user.name.firstName, res.user.name.lastName].join(
                          " "
                        )
                      : "",
                  });
                }}
                render={(props) => (
                  <button
                    {...props}
                    className={style.AuthBtn}
                    onClick={(...args) => {
                      setIsLoading(true);
                      props.onClick(...args);
                    }}
                  >
                    <div className={style.AuthIcon}>
                      <img
                        src={assetPrefix + "images/apple-signin.svg"}
                        alt="Sign in with Apple"
                      />
                    </div>
                    <p className={style.AuthBtnLabel}>
                      <Trans i18nKey="continue_with_apple">
                        Continue with Apple
                      </Trans>
                    </p>
                  </button>
                )}
              />
            </Fragment>
          ) : (
            <Fragment />
          )}

          {panel === "MAIL" && (
            <LoginWithMail
              setPanel={setPanel}
              setJwtTkn={setJwtTkn}
              setIsFocusPassword={setIsFocusPassword}
            />
          )}

          {panel === "MAIL_REGISTER" && (
            <RegisterWitMail
              referral={referral}
              setPanel={setPanel}
              setJwtTkn={setJwtTkn}
              setIsFocusPassword={setIsFocusPassword}
            />
          )}

          {panel === "MAIL_REQUEST_FORGOT" && (
            <RequestForgot setPanel={setPanel} />
          )}

          {panel === "MAIL_REQUEST_FORGOT_SUCCESS" && (
            <RequestForgotSuccess setPanel={setPanel} />
          )}

          {panel === "SSO_REGISTER_FINAL" && (
            <RegisterFinalSSO
              tokenId={tokenId}
              onSubmit={async (res) => {
                if (signVendor === "google") return await responseGoogle(res);
                if (signVendor === "apple") return await responseApple(res);
              }}
              setPanel={setPanel}
              referral={referral}
              signVendor={signVendor}
              fullName={fullName}
            />
          )}

          {panel === "SSO_EMAIL_EXISTS" && (
            <SsoEmailExists setPanel={setPanel} />
          )}
        </Fragment>
      )}

      {panel === "COMPLETED" && <Completed />}

      {panel == "MOBILE_SUCCESS" && <AfterMobile url={jwtTkn} />}
    </Fragment>
  );
};

const LoginWithMail = ({ setPanel, setJwtTkn, setIsFocusPassword }) => {
  const { t } = useTranslation();
  const { authModal, revalidate } = useUserContext();
  const turnstileRef = useRef<TurnstileInstance>();

  const onLogin = async (values, { setErrors, setFieldValue }) => {
    authModal.setLoading();
    try {
      const headers = {};
      //@ts-ignore
      if (authModal.item?.mobile) {
        headers["X-Use-Token"] = "true";
      }
      const { data } = await api().post("/login", values, {
        withCredentials: true,
        headers,
      });
      //@ts-ignore
      if (data.data && authModal.item?.mobile) {
        setJwtTkn(sendToWebkit(data.data));
        setPanel("MOBILE_SUCCESS");
      }
      await revalidate();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        handleAxioError(e, { t, toast, setErrors, onError: null });
      }
      setFieldValue("captcha", "");
      turnstileRef.current?.reset();
    }
    authModal.finishLoading();
  };
  const passToggle = useTogglePassword();

  const validation = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(t("validation_required", { field: t("email") }))
      .max(255),
    password: Yup.string()
      .required(t("validation_required", { field: t("password") }))
      .max(255),
    captcha: Yup.string().required(),
  });

  return (
    <div className="w-full">
      <h1 className="font-montserrat text-2xl text-center font-bold mt-3 mb-1">
        <Trans i18nKey="login">Log In</Trans>
      </h1>
      <p className="text-center mb-5 text-gray-500">
        <Trans i18nKey="input_auth_info_below">
          Input your authentication information below
        </Trans>
      </p>

      <Formik
        initialValues={{ email: "", password: "", captcha: "" }}
        validationSchema={validation}
        onSubmit={onLogin}
      >
        <Form>
          <div className="mb-3">
            <InputLabel>
              <Trans i18nKey="email">Email</Trans>
            </InputLabel>
            <FieldInput
              type="text"
              name="email"
              className="w-full !py-2.5 !px-3"
              placeholder="saidi@s.id"
              autoFocus
              maxLength={255}
            />
            <ErrorField name="email" />
          </div>

          <div className="mb-3">
            <InputLabel>
              <Trans i18nKey="password">Password</Trans>
            </InputLabel>
            <div className="relative">
              <FieldInput
                type={passToggle.isOpen ? "text" : "password"}
                name="password"
                className="w-full !py-2.5 !px-3 !pr-9"
                placeholder="Secret"
                onFocus={setIsFocusPassword.bind(null, true)}
                onBlur={setIsFocusPassword.bind(null, false)}
                maxLength={255}
              />
              <TogglePassword {...passToggle} />
            </div>
            <ErrorField name="password" />

            <div className="text-right mt-1">
              <a
                href="#"
                className="text-red-500 underline text-sm"
                onClick={(e) => {
                  e.preventDefault();
                  setPanel("MAIL_REQUEST_FORGOT");
                }}
              >
                <Trans i18nKey="forgot_password">Forgot Password</Trans>
              </a>
            </div>
          </div>

          <div className="mb-4 flex justify-center">
            <InputCaptcha ref={turnstileRef} />
          </div>

          <div className="mb-3">
            <FormikSubmit className="w-full !py-2.5">
              <Trans i18nKey="sign_in">Sign In</Trans>
            </FormikSubmit>
          </div>

          <p className="text-center mt-4 text-gray-600">
            <Trans i18nKey="dont_have_account">Dont have account</Trans>?
          </p>

          <div className="text-center">
            <a
              href="#"
              className="text-red-500 underline inline-block my-3"
              onClick={(e) => {
                e.preventDefault();
                setPanel("MAIL_REGISTER");
              }}
            >
              <Trans i18nKey="create_account">Create Account</Trans>
            </a>
          </div>

          <div className="mt-3">
            <Button
              className="w-full"
              color="white"
              onClick={setPanel.bind(null, "MAIN")}
            >
              <ArrowLeftIcon className="w-4 inline-block -mt-0.5 mr-1" />{" "}
              <Trans i18nKey="back">Back</Trans>
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

const RegisterWitMail = ({
  referral,
  setPanel,
  setJwtTkn,
  setIsFocusPassword,
}) => {
  const turnstileRef = useRef<TurnstileInstance>();
  const { query } = useRouter();
  const { t } = useTranslation();
  const user = useUserContext();
  const passToggle = useTogglePassword();
  const referralSlide = useSlideDown();

  const registerValidate = useMemo(
    () =>
      Yup.object().shape({
        full_name: Yup.string()
          .matches(/^[a-z][a-z\s]*[a-z]$/i, { message: t("invalid_name") })
          .required(t("validation_required", { field: t("full_name") }))
          .max(255),
        email: Yup.string()
          .email()
          .required(t("validation_required", { field: t("email") }))
          .test({
            test: (value) => !/\.con$/.test(value || ""),
            message: "oops typo!",
          })
          .test({
            test: (value) => !/@s\.id$/.test(value || ""),
            message: "Is not valid email!",
          })
          .max(255),
        password: Yup.string()
          .min(5, t("validation_minimum", { min: 5 }))
          .required(t("validation_required", { field: t("password") }))
          .max(255),
        captcha: Yup.string().required(t("please_solve_captcha")),
        terms: Yup.boolean().isTrue(t("please_accept_tos")),
        referral: Yup.string()
          .matches(/^[a-z0-9]+$/i, {
            message: t("referral_format_invalid"),
          })
          .nullable(),
      }),
    [t]
  );

  const onSignup = async (values, { setErrors }) => {
    try {
      const payload: any = {
        full_name: values.full_name,
        email: values.email,
        password: values.password,
        captcha: values.captcha,
      };

      if (values.referral) {
        payload.referral = values.referral;
      }

      const headers = {};
      //@ts-ignore
      if (user.authModal.item?.mobile) {
        headers["X-Use-Token"] = "true";
      }
      const { data } = await api().post("/register", payload, { headers });
      //@ts-ignore
      if (data.data && user.authModal.item?.mobile) {
        setJwtTkn(sendToWebkit(data.data));
        setPanel("MOBILE_SUCCESS");
      }
      await user.revalidate();
      if ((query?.name as string)?.length > 0) {
        setTimeout(() => {
          //@ts-ignore
          window.location =
            "/dashboard/microsite?createWithName=" + query?.name;
        }, 300);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        handleAxioError(e, { setErrors, t, toast, onError: null });
      }
      turnstileRef.current?.reset();
    }
  };
  return (
    <div className="w-full">
      <h1 className="font-montserrat text-2xl text-center font-bold mt-3 mb-1">
        <Trans i18nKey="register">Register</Trans>
      </h1>
      <p className="text-center mb-5 text-gray-500">
        <Trans i18nKey="fill_your_info_below">
          Fill your user information below
        </Trans>
      </p>

      <Formik
        initialValues={{
          referral,
          full_name: "",
          email: "",
          password: "",
          captcha: "",
          terms: false,
          newsletter: false,
        }}
        validationSchema={registerValidate}
        onSubmit={onSignup}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <div className="mb-3">
              <InputLabel>
                <Trans i18nKey="full_name">Full Name</Trans>
                <span className="text-red-500">*</span>
              </InputLabel>
              <FieldInput
                type="text"
                name="full_name"
                className="w-full !py-2.5 !px-3"
                placeholder="Si Saidi"
                autoFocus
                maxLength={255}
              />
              <ErrorField name="full_name" />
            </div>
            <div className="mb-3">
              <InputLabel>
                <Trans i18nKey="email">Email</Trans>
                <span className="text-red-500">*</span>
              </InputLabel>
              <FieldInput
                type="text"
                name="email"
                className="w-full !py-2.5 !px-3"
                placeholder="saidi@s.id"
                autoComplete="off"
                required
                maxLength={255}
              />
              <ErrorField name="email" />
            </div>

            <div className="mb-3">
              <InputLabel>
                <Trans i18nKey="password">Password</Trans>
                <span className="text-red-500">*</span>
              </InputLabel>
              <div className="relative">
                <FieldInput
                  type={passToggle.isOpen ? "text" : "password"}
                  name="password"
                  className="w-full !py-2.5 !px-3 !pr-9"
                  placeholder="Secret"
                  onFocus={setIsFocusPassword.bind(null, true)}
                  onBlur={setIsFocusPassword.bind(null, false)}
                  autoComplete="new-password"
                  required
                  maxLength={255}
                />
                <TogglePassword {...passToggle} />
              </div>
              <ErrorField name="password" />
            </div>

            {referral ? (
              <div className="mb-6">
                <InputLabel>
                  <Trans i18nKey="referral_code">Referral Code</Trans>
                </InputLabel>

                <FieldInput
                  type="text"
                  name="referral"
                  className="w-full !py-2.5 !px-3"
                  placeholder="Code"
                  disabled={referral}
                  maxLength={255}
                />
              </div>
            ) : (
              <div className="mb-6 bg-slate-100 hover:bg-slate-200 transition-all border rounded-md">
                <button
                  className="flex w-full items-center justify-center font-medium text-sm  px-2 py-1"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    referralSlide.toggle();
                  }}
                >
                  <p className="flex-1">
                    <Trans i18nKey="referral_code">Referral Code</Trans>
                  </p>
                  {
                    <ChevronDownIcon
                      className={clsx(
                        "w-4 transition-all",
                        referralSlide.isOpen ? "rotate-180" : ""
                      )}
                    />
                  }
                </button>
                <SlideDown {...referralSlide}>
                  <div className="p-3">
                    <FieldInput
                      type="text"
                      name="referral"
                      className="w-full !py-2.5 !px-3"
                      placeholder="Code"
                      disabled={referral}
                      maxLength={255}
                    />
                    {!referral && (
                      <p className="text-sm mt-1 text-gray-600">
                        <InformationCircleIcon className="w-4 inline-block mr-1 -mt-1" />
                        <Trans i18nKey="referral_use_instruction">
                          Use this field for use referral code if you have one.
                        </Trans>
                      </p>
                    )}
                    <ErrorField name="referral" />
                  </div>
                </SlideDown>
              </div>
            )}

            <div className="mb-3">
              <label className="cursor-pointer text-sm">
                <FieldInput type="checkbox" name="terms" />
                <span className="ml-1">
                  <Trans i18nKey="agree_tos_label" />{" "}
                  <a
                    href="/page/terms-of-service"
                    className="text-red-600 underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Trans i18nKey="terms_of_service" />
                  </a>
                </span>
              </label>
              <ErrorField name="terms" />
            </div>

            <div className="mb-3">
              <label className="cursor-pointer text-sm">
                <FieldInput type="checkbox" name="newsletter" />
                <span className="ml-1">
                  <Trans i18nKey="newsletter_check_label" />
                </span>
              </label>
            </div>

            <div className="mb-4 flex justify-center">
              <InputCaptcha ref={turnstileRef} />
            </div>

            <div className="mb-3">
              <FormikSubmit className="w-full !py-2.5">
                <Trans i18nKey="create_account">Create Account</Trans>
              </FormikSubmit>
            </div>

            <div className="mt-3">
              <Button
                className="w-full"
                color="white"
                disabled={isSubmitting}
                onClick={setPanel.bind(null, "MAIN")}
              >
                <ArrowLeftIcon className="w-4 inline-block -mt-0.5 mr-1" /> Back
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const RequestForgot = ({ setPanel }) => {
  const user = useUserContext();
  const turnstileRef = useRef<TurnstileInstance>();
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email()
          .required(t("validation_required", { field: t("email") })),
      }),
    [t]
  );

  const onForgot = async (values, { setErrors }) => {
    user.authModal.setLoading();
    try {
      // await new Promise((r) => setTimeout(r, 2000));
      await api().post("/reset-password", values);
      setPanel("MAIL_REQUEST_FORGOT_SUCCESS");
    } catch (e) {
      if (axios.isAxiosError(e)) {
        handleAxioError(e, { setErrors, t, toast, onError: null });
      }
      turnstileRef.current?.reset();
    }
    user.authModal.finishLoading();
  };
  return (
    <div className="w-full">
      <h1 className="font-montserrat text-2xl text-center font-bold mt-3 mb-1">
        <Trans i18nKey="forgot_password">Forgot Password</Trans>
      </h1>
      <p className="text-center mb-5 text-gray-500">
        <Trans i18nKey="forgot_password_description">
          Input your account information below.
        </Trans>
      </p>

      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={onForgot}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="mb-3">
              <InputLabel>
                <Trans i18nKey="email">Email</Trans>
              </InputLabel>
              <FieldInput
                type="text"
                name="email"
                className="w-full !py-2.5 !px-3"
                placeholder="saidi@s.id"
                maxLength={255}
              />
              <ErrorField name="email" />
            </div>

            <div className="mb-4 flex justify-center">
              <InputCaptcha ref={turnstileRef} />
            </div>

            <div className="mb-3">
              <FormikSubmit className="w-full !py-2.5">
                <Trans i18nKey="continue">Continue</Trans>
              </FormikSubmit>
            </div>

            <div className="mt-3">
              <Button
                className="w-full"
                color="white"
                disabled={isSubmitting}
                onClick={setPanel.bind(null, "MAIN")}
              >
                <ArrowLeftIcon className="w-4 inline-block -mt-0.5 mr-1" />{" "}
                <Trans i18nKey="back">Back</Trans>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const RequestForgotSuccess = ({ setPanel }) => {
  return (
    <div className="w-full">
      <h1 className="font-montserrat text-2xl text-center font-bold mt-3 mb-1">
        <Trans i18nKey="forgot_password_success">Success</Trans>
      </h1>
      <p className="text-center mb-5 text-gray-500">
        <Trans i18nKey="forgot_password_success_description">
          Check your mail inbox / spam to continue reset your password.
        </Trans>
      </p>

      <Button
        type="button"
        className="w-full !py-2.5"
        color="white"
        onClick={setPanel.bind(null, "MAIL")}
      >
        <ArrowLeftIcon className="w-4 inline-block -mt-0.5 mr-1" />{" "}
        <Trans i18nKey="back">Back</Trans>
      </Button>
    </div>
  );
};

const RegisterFinalSSO = ({
  tokenId,
  onSubmit,
  setPanel,
  referral = "",
  signVendor,
  fullName = "",
}) => {
  const { t } = useTranslation();

  const registerValidate = useMemo(
    () =>
      Yup.object().shape({
        referral: Yup.string()
          .matches(/^[a-z0-9]+$/i, {
            message: t("referral_format_invalid"),
          })
          .nullable(),
        terms: Yup.boolean().isTrue(t("please_accept_tos")),
      }),
    [t]
  );

  return (
    <div className="w-full">
      <h1 className="font-montserrat text-2xl text-center font-bold mt-3 mb-1">
        <Trans i18nKey="register_almost_done" />
      </h1>
      <p className="text-center mb-5 text-gray-500">
        <Trans i18nKey="register_almost_done_description" />
      </p>

      <Formik
        initialValues={{
          full_name: fullName,
          referral,
          newsletter: false,
          terms: false,
          access_token: tokenId,
        }}
        onSubmit={onSubmit}
        validationSchema={registerValidate}
      >
        <Form>
          {signVendor === "apple" && (
            <div className="mb-6">
              <InputLabel>
                <Trans i18nKey="full_name">Full Name</Trans>
              </InputLabel>
              <FieldInput
                type="text"
                name="full_name"
                className="w-full !py-2.5 !px-3"
                placeholder="Full Name"
                maxLength={255}
              />
            </div>
          )}

          <div className="mb-6">
            <InputLabel>
              <Trans i18nKey="referral_code">Referral Code</Trans> (
              <Trans i18nKey="optional">Optional</Trans>)
            </InputLabel>
            <FieldInput
              type="text"
              name="referral"
              className="w-full !py-2.5 !px-3"
              placeholder="Code"
              disabled={!!referral}
              maxLength={255}
            />
            {!referral && (
              <p className="text-sm mt-1 text-gray-600">
                <InformationCircleIcon className="w-4 inline-block mr-1 -mt-1" />
                Use this field for use referral code if you have one.
              </p>
            )}
            <ErrorField name="referral" />
          </div>

          <div className="mb-3">
            <label className="cursor-pointer text-sm">
              <FieldInput type="checkbox" name="terms" />
              <span className="ml-1">
                <Trans i18nKey="agree_tos_label" />{" "}
                <a
                  href="/page/terms-of-service"
                  className="text-red-600 underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans i18nKey="terms_of_service" />
                </a>
              </span>
            </label>
            <ErrorField name="terms" />
          </div>

          <div className="mb-3">
            <label className="cursor-pointer text-sm">
              <FieldInput type="checkbox" name="newsletter" />
              <span className="ml-1">
                <Trans i18nKey="newsletter_check_label" />
              </span>
            </label>
          </div>

          <div className="mb-3">
            <FormikSubmit className="w-full !py-2.5">
              <Trans i18nKey="continue">Continue</Trans>
            </FormikSubmit>
          </div>
          <Button
            type="button"
            className="w-full !py-2.5"
            color="white"
            onClick={setPanel.bind(null, "MAIN")}
          >
            <Trans i18nKey="cancel">Cancel</Trans>
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

const Completed = () => {
  return (
    <div className="w-full">
      <h1 className="font-montserrat text-2xl text-center font-bold mt-3 mb-1">
        <Trans i18nKey="please_wait">Please Wait</Trans>
      </h1>
      <p className="text-center mb-5 text-gray-500">
        <Trans i18nKey="you_will_redirect">
          We will redirect you in a moment.
        </Trans>
      </p>
      <Spinner className="w-8 animate-spin mx-auto mb-4" />
    </div>
  );
};

const AfterMobile = ({ url }) => {
  return (
    <>
      <h1 className="text-center text-lg font-montserrat font-bold mt-4">
        Login Success!
      </h1>
      <p className="text-center mb-3">Click button below to continue</p>
      <Button
        as="href"
        className="w-full text-center flex items-center justify-center"
        color="blue"
        href={url}
      >
        Continue to Login <ArrowRightIcon className="w-4 ml-2" />
      </Button>
    </>
  );
};

const SsoEmailExists = ({ setPanel }) => {
  return (
    <div className="w-full">
      <h1 className="font-montserrat text-xl text-center font-bold mt-3 mb-1">
        ❗️ <Trans i18nKey="email_already_exist">Email Already Exists</Trans>
      </h1>
      <p className="text-center mb-5 text-gray-500">
        <Trans i18nKey="email_exists_on_sso">
          Looks you&apos;re already register s.id account with your email.
        </Trans>
      </p>

      <button className={style.AuthBtn} onClick={setPanel.bind(null, "MAIL")}>
        <div className={style.AuthIcon}>
          <EnvelopeIcon className="text-gray-600 w-5" />
        </div>
        <p className={style.AuthBtnLabel}>
          <Trans i18nKey="continue_with_email">Continue with Email</Trans>
        </p>
      </button>

      <div className="mt-3">
        <Button
          className="w-full"
          color="white"
          onClick={setPanel.bind(null, "MAIN")}
        >
          <ArrowLeftIcon className="w-4 inline-block -mt-0.5 mr-1" />{" "}
          <Trans i18nKey="back">Back</Trans>
        </Button>
      </div>
    </div>
  );
};
export default UserAuthModal;
