import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import InputMaskC from "react-input-mask";
import Style from "./Input.module.scss";

import { Field, getIn, useFormikContext } from "formik";
import React, {
  InputHTMLAttributes,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
  forwardRef,
  useMemo,
  useState,
} from "react";

interface Props extends InputHTMLAttributes<any> {
  mask?: string;
  inputSize?: "sm" | "md" | "lg" | "";
}

interface SelectProp extends SelectHTMLAttributes<any> {
  inputClassName?: string;
  inputSize?: "sm" | "md" | "lg" | "";
}

interface TextareaProp extends TextareaHTMLAttributes<any> {}

export const InputLabel: React.FC<any> = ({
  children = null,
  required = false,
  className = "",
}) => {
  return (
    <label
      className={clsx(
        "block font-semibold font-worksans text-gray-700 dark:text-gray-300 mb-1 truncate",
        className
      )}
    >
      {children}
      {required && <span className="text-red-500">*</span>}
    </label>
  );
};

export const Input = forwardRef(function Input(props: Props, ref: any) {
  return (
    <input
      {...props}
      ref={ref}
      className={clsx(
        Style.Input,
        props.inputSize === "lg" ? Style.Lg : Style.Base,
        props.className
      )}
    />
  );
});

export const FieldInput = (props: Props) => {
  return <Field {...props} as={Input} />;
};

export const FieldSelect = ({ className, ...props }: SelectProp) => {
  return (
    <div className={clsx("relative", className)}>
      <div className="absolute top-0 bottom-0 right-2 flex items-center pointer-events-none">
        <ChevronDownIcon className="w-6 dark:text-white" />
      </div>
      <Field
        as="select"
        {...props}
        className={clsx(
          Style.Input,
          "appearance-none w-full !pr-9",
          props.inputClassName,
          props.inputSize === "lg" ? Style.Lg : Style.Base
        )}
      />
    </div>
  );
};

export const Textarea = forwardRef(function Textarea(
  { className, ...props }: Props,
  ref
) {
  return (
    <textarea
      {...props}
      ref={ref as any}
      className={clsx(Style.Input, Style.BaseTextarea, className)}
    />
  );
});

export const FieldTextarea = ({ className, ...props }: TextareaProp) => {
  return <Field {...props} as={Textarea} className={className} />;
};

export const InputTags = ({ name }) => {
  const [value, setValue] = useState("");
  const { values, setFieldValue } = useFormikContext();
  const tags = useMemo(() => {
    const tags = getIn(values, name) || [];
    return tags;
  }, [name, values]);

  const appendTags = (value) => {
    if (tags.indexOf(value) === -1) {
      const values = [...tags];
      values.push(value);
      setFieldValue(name, values);
    }
  };

  const deleteTags = (index) => {
    const values = [...tags];
    values.splice(index, 1);
    setFieldValue(name, [...values]);
  };

  return (
    <div>
      <div className="flex flex-wrap items-center bg-white dark:bg-black border border-gray-300 dark:border-gray-800 overflow-hidden rounded-md">
        {tags.map((item, index) => (
          <div
            className="px-1 flex items-center bg-slate-200 dark:bg-gray-800 rounded-md m-1"
            key={index}
          >
            <span>{item}</span>
            <button
              type="button"
              className="bg-red-500 text-white ml-1 rounded-full"
              onClick={() => deleteTags(index)}
            >
              <XMarkIcon className="w-3" />
            </button>
          </div>
        ))}{" "}
        <input
          type="text"
          placeholder="New Tags"
          value={value}
          className="py-1 px-2 outline-none block appearance-none bg-transparent"
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === "Backspace" && value === "") {
              e.preventDefault();
              deleteTags(tags.length - 1);
            }
            if (e.code === "Enter") {
              e.preventDefault();
              if (value !== "") {
                setValue("");
                appendTags(value);
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export const InputMask = ({ className, mask, ...props }: Props) => {
  return (
    <Field
      {...props}
      as={InputMaskC}
      mask={mask || "9999-99-99 99:99:99"}
      className={clsx(
        Style.Input,
        props.inputSize === "lg" ? Style.Lg : Style.Base,
        className
      )}
    />
  );
};
