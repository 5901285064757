const i18n = require("@sid/core/i18n");
module.exports = {
  i18n: {
    debug: process.env.NODE_ENV !== "production",
    defaultLocale: i18n.defaultLocale,
    locales: i18n.locales,
    // saveMissing: process.env.NODE_ENV !== "production",
    // reloadOnPrerender: process.env.NODE_ENV !== "production",
  },
  ns: [
    "about",
    "common",
    "dashboard",
    "features",
    "landing",
    "microsite_editor",
    "microsite_tutorial",
    "subscription",
    "ticket",
    "tos",
  ],
  reloadOnPrerender: process.env.NODE_ENV !== "production",
  // saveMissing: true,
  serializeConfig: false,
};
